import * as React from "react";
import { Card, Grid, Typography, Box, Container, Button, Stack } from "@mui/material";
import AbondonedCart from "Assets/AbondonedCart.svg";
import Checkout from "Assets/Checkout.svg";
import Engagement from "Assets/Engagement.svg";
import Revenue from "Assets/Revenue.svg";
import chatInsights1 from "Assets/chatInsights1.png";
import chatInsights2 from "Assets/chatInsights2.png";
import Slider from "react-slick";
import { gradientTextStyle } from "../style/gardientText";

function EngageNewUserInfo({ refMap, theme ,handleTouch}) {
  const sliderRef = React.useRef(null);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [])
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <></>,
    nextArrow: <></>,
    appendDots: (dots) => (
      <Box
        sx={{
          position: "relative",
          bottom: isMobile?-5: -10,
          left: "50%",
          zIndex:1,
          transform: "translateX(-50%)",
          "& ul": {
            margin: 0,
            padding: 0,
            display: "flex",
            listStyle: "none",
            justifyContent: "center",
          },
          "& li": {
            margin: "0 5px",
          },
        }}
      >
        <ul>{dots}</ul>
      </Box>
    ),
    customPaging: () => (
      <Box
        sx={{
          width: "9px",
          height: "9px",
          borderRadius: "50%",
          background: "white",
        }}
      ></Box>
    ),
  };

  const chatInsights = [chatInsights1, chatInsights2];

  return (
    <Stack sx={{ width: "100%", alignItems: "center" }} ref={refMap} container>
      <Box sx={{ width: { xs: "100%", md: "80%", lg: "70%" }, mt: isMobile ? 5 : 10 }}>
    <Container
      ref={refMap}
      sx={{  position: "relative" }}
      mt={-20}
      onTouchStart={handleTouch}
    >
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={7}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Card
            sx={{
              backgroundColor: "primary.cardBackground",
              borderRadius: theme.typography.pxToRem(45),
              p: 4,
              color: "white",
              textAlign: "left",
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: theme.typography.pxToRem(30),
                  md: theme.typography.pxToRem(50),
                },
                textAlign: "center",
                ...gradientTextStyle,
              }}
            >
              User Engagement Like ​Never Before
            </Typography>
            <Typography
              sx={{
                mt: 2,
                fontSize: {
                  xs: theme.typography.pxToRem(20),
                  md: theme.typography.pxToRem(20),
                },
                lineHeight: {
                  xs: theme.typography.pxToRem(24),
                  md: theme.typography.pxToRem(30),
                },
                textAlign: "center",
                color: "white",
              }}
            >
              Elevate customer experience and drive ​conversions with tailored,
              seamless ​interactions at every touchpoint.
            </Typography>
          </Card>
          <Card
            sx={{
              backgroundColor: "primary.cardBackground",
              borderRadius: theme.typography.pxToRem(45),
              p: 4,
              color: "white",
              display: "flex",
              flexDirection: "column",
              gap: { xs: 2, md: 4 },
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <img
                  src={Engagement}
                  alt="Engagement"
                  style={{
                    width: "45px",
                    height: "45px",
                    marginBottom: "8px",
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.pxToRem(16),
                        md: theme.typography.pxToRem(20),
                      },
                    }}
                  >
                    35% increase
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.pxToRem(16),
                        md: theme.typography.pxToRem(20),
                      },
                    }}
                  >
                    In Engagement
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <img
                  src={Revenue}
                  alt="Revenue"
                  style={{
                    width: "48px",
                    height: "48px",
                    marginBottom: "8px",
                  }}
                  loading="lazy"
                />
                <Box>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.pxToRem(16),
                        md: theme.typography.pxToRem(20),
                      },
                    }}
                  >
                     20% Uptick
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.pxToRem(16),
                        md: theme.typography.pxToRem(20),
                      },
                      color: "white",
                    }}
                  >
                    In Revenue
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <img
                  src={Checkout}
                  alt="Checkout"
                  style={{
                    width: "48px",
                    height: "48px",
                    marginBottom: "8px",
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.pxToRem(16),
                        md: theme.typography.pxToRem(20),
                      },
                    }}
                  >
                    45%
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.pxToRem(16),
                        md: theme.typography.pxToRem(20),
                      },
                    }}
                  >
                    Faster Checkout
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <img
                  src={AbondonedCart}
                  alt="Abandoned Cart"
                  style={{
                    width: "48px",
                    height: "48px",
                    marginBottom: "8px",
                  }}
                  loading="lazy"
                />
                <Box>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.pxToRem(16),
                        md: theme.typography.pxToRem(20),
                      },
                    }}
                  >
                     60% Less
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: theme.typography.pxToRem(16),
                        md: theme.typography.pxToRem(20),
                      },
                    }}
                  >
                    Abandoned Cart
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>
         {isMobile?null:   <Box
          sx={{
            
            width: "100%",
            display: "flex",
            justifyContent: { xs: "center", sm: "center" }, // Center button on mobile
          }}
        >
        <Button
            variant="outlined"
            type="submit"
            color="primary"
            size="small"
            disableRipple
            sx={{
              mt: 4,
              color: "white",
              textTransform: "none",
              borderRadius: (theme) => theme.typography.pxToRem(28),
              padding: (theme) =>
                `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
                  20
                )}`,
              fontWeight: "bold",
              backgroundColor: "primary.button2",
              fontSize: { xs: theme.typography.pxToRem(16), md: theme.typography.pxToRem(20) },
              "&:hover": {
                backgroundColor: "primary.button2",
                borderColor: "primary.button2",
                boxShadow: (theme) => `0 0 ${theme.typography.pxToRem(6)} #fff`,
              },
            }}
            onClick={() => window.open("https://app.ulai.in/user/login")}
          >
            Try For Free
          </Button>
        </Box>}
        </Grid>

        <Grid item xs={12} sm={5}>
          <Card
            sx={{
              ml: { xs: 4, sm: 0 },
              mr: { xs: 4, sm: 0 },
              backgroundColor: "transparent",
              borderRadius: theme.typography.pxToRem(45),
              p: 0,
              color: "white",
              display: "flex",
              flexDirection: "column",
              gap: 4,
              position: "relative",
            }}
          >
            <div
              style={{
                height: "400px",
                width: "400px",
                filter: "blur(100px)",
                position: "absolute",
                transform: "translate(70%, 80%) translateZ(0)",
              }}
            ></div>
            
            <Slider ref={sliderRef} {...settings}>
              {chatInsights.map((chat, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height:isMobile?"500px":"650px",
                    width: "110%",
                    width: { sm: "100%", md: "70%" },
                    background: "radial-gradient(circle closest-side, rgba(112, 25, 255, 0.9) 0%, rgba(112, 25, 255, 0) 100%)",
                    backgroundSize: "80% 80%", // Make the gradient smaller
                    backgroundPosition: "center", // Center the gradient
                    backgroundRepeat: "no-repeat", // Ensure the gradient does not repeat
                    borderRadius: theme => theme.typography.pxToRem(16),
                    padding: theme => theme.typography.pxToRem(2),
                    boxSizing: "border-box",
                    position: "relative", // Ensure that the gradient is contained
                    overflow: "hidden", 
                    
                  }}
                >
    
                  <img
                    src={chat}
                    style={{
                      width: "110%",
                      height: "95%",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                    loading="lazy"
                    alt={`Chat Insight ${index + 1}`}
                  />
                 
                </Box>
              ))}
            </Slider>
          </Card>
          {isMobile?   <Box
          sx={{
            
            width: "100%",
            display: "flex",
            justifyContent: { xs: "center", sm: "center" }, // Center button on mobile
          }}
        >
        <Button
            variant="outlined"
            type="submit"
            color="primary"
            size="small"
            disableRipple
            sx={{
              mt: 4,
              color: "white",
              textTransform: "none",
              borderRadius: (theme) => theme.typography.pxToRem(28),
              padding: (theme) =>
                `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
                  20
                )}`,
              fontWeight: "bold",
              backgroundColor: "primary.button2",
              fontSize: { xs: theme.typography.pxToRem(16), md: theme.typography.pxToRem(20) },
              "&:hover": {
                backgroundColor: "primary.button2",
                borderColor: "primary.button2",
                boxShadow: (theme) => `0 0 ${theme.typography.pxToRem(6)} #fff`,
              },
            }}
            onClick={() => window.open("https://app.ulai.in/user/login")}
          >
            Try For Free
          </Button>
        </Box>:null}
        </Grid>
      </Grid>
    </Container>
    </Box>
    </Stack>
  );
}

export default EngageNewUserInfo;
