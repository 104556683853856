
import { Route, Routes } from "react-router-dom";
import Homepage from "../Homepage";
import DataPolicy from "../Component/DataPolicy";
import PrivacyPolicy from "../Component/PrivacyPolicy";
import TermsOfService from "../Component/TermsOfService";
import { useTheme } from "@emotion/react";
import PricingPage from "../Component/PricingPage";

export default function Router({
  calendlyOpen,
  setCalendlyOpen,
  activeTab,
  setActiveTab,
  appreciationRef,
  customerStoriesRef,
  engagementRef,
  ecomJourneyRef,
  whatsappChannelRef,
  comprehensiveAnalyticsRef,
  integrationsRef,
  codeIntegrationRef,
  ulaiBenefitsRef,
  trustedBrandsRef,
  supportedByRef,
  scheduleDemoRef
}) {
  const theme = useTheme();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Homepage
            theme={theme}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            appreciationRef={appreciationRef}
            customerStoriesRef={customerStoriesRef}
            engagementRef={engagementRef}
            ecomJourneyRef={ecomJourneyRef}
            whatsappChannelRef={whatsappChannelRef}
            comprehensiveAnalyticsRef={comprehensiveAnalyticsRef}
            integrationsRef={integrationsRef}
            codeIntegrationRef={codeIntegrationRef}
            ulaiBenefitsRef={ulaiBenefitsRef}
            trustedBrandsRef={trustedBrandsRef}
            supportedByRef={supportedByRef}
            scheduleDemoRef={scheduleDemoRef}
            calendlyOpen={calendlyOpen} setCalendlyOpen={setCalendlyOpen} 
          />
        }
      />
      <Route path="/data-policy" element={<DataPolicy theme={theme} />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy theme={theme} />} />
      <Route
        path="/terms-condition"
        element={<TermsOfService theme={theme} />}
      />
        <Route
        path="/pricing"
        element={<PricingPage theme={theme} />}
      />
    </Routes>
  );
}