import * as React from "react";
import { Grid, Button, Typography, Container, Box, Stack } from "@mui/material";
import twoWayMarketing from "Assets/twoWayMarketing.png";
import { gradientTextStyle } from "../style/gardientText";

function TwoWayWhatsappMarketing({ refMap, theme }) {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [])
  return (
    <Stack sx={{ width: "100%", alignItems: "center" }} ref={refMap} container>
      <Box sx={{ width: { xs: "100%", md: "80%", lg: "70%" }, mt: isMobile ? 0 : 10 }}>
        <Container


        >
          {isMobile ? <Grid container>
            {/* Text Section */}
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                p: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", sm: "flex-start" }, // Center align on mobile
                textAlign: { xs: "center", sm: "left" }, // Center text on mobile
                order: { xs: 1, sm: 1 } // Text section is first on mobile and same on laptop
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: theme.typography.pxToRem(30),
                    md: theme.typography.pxToRem(50),

                  },
                  ...gradientTextStyle,
                }}
              >
                Interactive ​Whatsapp ​Marketing
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: theme.typography.pxToRem(20),
                    md: theme.typography.pxToRem(20),
                  },
                  mt: 2,
                  mb: 2,
                  color: "white",
                }}
              >
                Leverage WhatsApp as a ​dynamic conversational channel ​with Ulai's interactive marketing. ​Create personalized, engaging ​campaigns that captivate your ​audience and keep your ​messaging spam-free with AI-​powered segmentation. Turn ​WhatsApp into your top-​performing channel and watch ​your RoAS soar.

              </Typography>
            </Grid>

            {/* Image Section */}
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                p: 6.5,
                color: "white",
                display: "flex",
                gap: 4,
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                borderRight: { xs: "none", sm: "1px solid #333" }, // Remove right border on mobile
                borderBottom: "1px solid #333",
                background: `linear-gradient(90deg, #333 1px, transparent 1px),
                 linear-gradient(180deg, #333 1px, transparent 1px)`,
                backgroundSize: `${theme.typography.pxToRem(100)}
                              ${theme.typography.pxToRem(100)}`,
                backgroundPositionY: 0,
                backgroundRepeat: "round",
                order: { xs: 2, sm: 0 } // Image section comes second on mobile and first on laptop
              }}
            >
              <img
                src={twoWayMarketing}
                style={{
                  height: "auto",
                  maxWidth: "120%",
                  objectFit: "cover",
                  zIndex: 1,
                }}
                alt="image"
              />
            </Grid>

            {/* Button Section */}
            <Grid
              item
              xs={12}
              sx={{
                display: { xs: "flex", sm: "none" }, // Display only on mobile view
                justifyContent: "center", // Center button on mobile
                order: { xs: 3, sm: 2 }, // Button section comes last on mobile
                p: 6.5,
              }}
            >
              <Box
                sx={{
                  mt: 2,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  type="submit"
                  color="primary"
                  size="small"
                  disableRipple
                  sx={{
                    mt: 2,
                    color: "white",
                    textTransform: "none",
                    borderRadius: (theme) => theme.typography.pxToRem(28),
                    padding: (theme) =>
                      `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
                        20
                      )}`,
                    fontWeight: "bold",
                    backgroundColor: "primary.button2",
                    fontSize: { xs: theme.typography.pxToRem(16), md: theme.typography.pxToRem(20) },
                    "&:hover": {
                      backgroundColor: "primary.button2",
                      borderColor: "primary.button2",
                      boxShadow: (theme) => `0 0 ${theme.typography.pxToRem(6)} #fff`,
                    },
                  }}
                  onClick={() => window.open("https://app.ulai.in/user/login")}
                >
                  Try For Free
                </Button>
              </Box>
            </Grid>
          </Grid> : <Grid  spacing={4} container justifyContent={"center"} alignItems={"center"}>


            {/* Text and Button Section */}
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                p: 6.5,
                pt: {xs:0,sm:0,md:10},
                display: "flex",
                flexDirection: "column",

                alignItems: { xs: "center", sm: "flex-start" }, // Center align on mobile
                textAlign: { xs: "center", sm: "left" }, // Center text on mobile
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: theme.typography.pxToRem(30),
                    md: theme.typography.pxToRem(50),
                  },
                  ...gradientTextStyle,
                }}
              >
                Interactive ​Whatsapp ​Marketing
              </Typography>
              <Typography
                sx={{
                  fontSize: {

                    xs: theme.typography.pxToRem(20),
                    md: theme.typography.pxToRem(20),
                  },
                  mt: 2,
                  color: "white",
                }}
              >
                Leverage WhatsApp as a ​dynamic conversational channel ​with Ulai's interactive marketing. ​Create personalized, engaging ​campaigns that captivate your ​audience and keep your ​messaging spam-free with AI-​powered segmentation. Turn ​WhatsApp into your top-​performing channel and watch ​your RoAS soar.
              </Typography>
              <Box
                sx={{
                  mt: 6,
                  width: "100%",
                  display: "flex",
                  justifyContent: { xs: "center", sm: "center" }, // Center button on mobile
                }}
              >
                <Button
                  variant="outlined"
                  type="submit"
                  color="primary"
                  size="small"
                  disableRipple
                  sx={{
                    mt: 6,
                    color: "white",
                    textTransform: "none",
                    borderRadius: (theme) => theme.typography.pxToRem(28),
                    padding: (theme) =>
                      `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
                        20
                      )}`,
                    fontWeight: "bold",
                    backgroundColor: "primary.button2",
                    fontSize: { xs: theme.typography.pxToRem(16), md: theme.typography.pxToRem(20) },
                    "&:hover": {
                      backgroundColor: "primary.button2",
                      borderColor: "primary.button2",
                      boxShadow: (theme) => `0 0 ${theme.typography.pxToRem(6)} #fff`,
                    },
                  }}
                  onClick={() => window.open("https://app.ulai.in/user/login")}
                >
                  Try For Free
                </Button>
              </Box>
            </Grid>

            {/* Image Section */}
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                p: 6.5,
                color: "white",
                display: "flex",
                gap: 4,
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                borderRight: { xs: "none", sm: "1px solid #333" }, // Remove right border on mobile
                borderBottom: "1px solid #333",
                background: `linear-gradient(90deg, #333 1px, transparent 1px),
               linear-gradient(180deg, #333 1px, transparent 1px)`,
                backgroundSize: `${theme.typography.pxToRem(70)}
                            ${theme.typography.pxToRem(70)}`,
                backgroundPositionY: 0,
                backgroundRepeat: "round",
              }}
            >
              <img
                src={twoWayMarketing}
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  objectFit: "cover",
                  zIndex: 1,
                }}
                alt="image"
              />
            </Grid>
          </Grid>}
        </Container>
      </Box>
    </Stack>
  );
}
export default TwoWayWhatsappMarketing;
