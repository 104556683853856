import * as React from "react";
import { Grid, Typography, Button, Container, Box,Stack } from "@mui/material";
import endToEndShopingExp from "Assets/endToEndShopingExp.svg";
import { gradientTextStyle } from "../style/gardientText";

function EndtoEndShopingExperience({
  refMap,
  theme,
  calendlyOpen,
  setCalendlyOpen,
}) {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Stack sx={{ width: "100%", alignItems: "center" }} ref={refMap}>
      <Box sx={{ width: { xs: "100%", md: "80%",lg:"70%"}, mt: 10 }}>
        <Container>
      {isMobile ? <Grid container>
        {/* Text Section */}
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            p: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "flex-start" }, // Center align on mobile
            textAlign: { xs: "center", sm: "left" }, // Center text on mobile
            order: { xs: 1, sm: 1 } // Text section is first on mobile and same on laptop
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: theme.typography.pxToRem(30),
                md: theme.typography.pxToRem(50),
              },
              ...gradientTextStyle,
            
            }}
          >
            From Discovery ​to Delivery{" "}
          </Typography>
          <Typography
            sx={{
              fontSize: {
           
                  xs: theme.typography.pxToRem(20),
                  md: theme.typography.pxToRem(20),
     
              },
              mt: 2,
              mb:2,
              color: "white",
            }}
          >
            ULai ensures a smooth and ​personalized shopping ​experience, guiding customers ​effortlessly from finding the ​perfect product to receiving it ​at their doorstep. Our robust ​post-purchase support handles ​all queries, ensuring customer ​satisfaction even after the sale.
          </Typography>
        </Grid>

        {/* Image Section */}
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            p: 6.5,
            color: "white",
            display: "flex",
            gap: 4,
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            borderRight: { xs: "none", sm: "1px solid #333" }, // Remove right border on mobile
            borderBottom: "1px solid #333",
            background: `linear-gradient(90deg, #333 1px, transparent 1px),
                 linear-gradient(180deg, #333 1px, transparent 1px)`,
            backgroundSize: `${theme.typography.pxToRem(100)}
                              ${theme.typography.pxToRem(100)}`,
            backgroundPositionY: 0,
            backgroundRepeat: "round",
            order: { xs: 2, sm: 0 } // Image section comes second on mobile and first on laptop
          }}
        >
          <img
            src={endToEndShopingExp}
            style={{
              height: "auto",
              maxWidth: "100%",
              objectFit: "cover",
              zIndex: 1,
            }}
            alt="image"
          />
        </Grid>

        {/* Button Section */}
        <Grid
          item
          xs={12}
          sx={{
            display: { xs: "flex", sm: "none" }, // Display only on mobile view
            justifyContent: "center", // Center button on mobile
            order: { xs: 3, sm: 2 }, // Button section comes last on mobile
            p: 6.5,
          }}
        >
          <Box
            sx={{
              mt: 2,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              size="small"
              disableRipple
              sx={{
                color: "white",
                borderRadius: (theme) => theme.typography.pxToRem(28),
                padding: (theme) =>
                  `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
                    20
                  )}`,
                textTransform: "none",
                fontWeight: "bold",
                backgroundColor: "primary.button1",
                fontSize: {
                  xs: theme.typography.pxToRem(16),
                  md: theme.typography.pxToRem(20),
                },
                "&:hover": {
                  backgroundColor: "primary.button1",
                  borderColor: "primary.button1",
                  boxShadow: (theme) =>
                    `0 0 ${theme.typography.pxToRem(6)} #fff`,
                },
              }}
              onClick={() => setCalendlyOpen(!calendlyOpen)}
            >
              Book Demo
            </Button>
          </Box>
        </Grid>
      </Grid> : 
      <Grid container  alignItems={"center"} justifyContent={"center"}>
      {/* Timer Icon and Text Section */}
      <Grid
          item
          xs={12}
          sm={6}
          sx={{
            p: 6.5,
            color: "white",
            display: "flex",
            gap: 4,
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            borderRight: { xs: "none", sm: "1px solid #333" }, // Remove right border on mobile
            borderBottom: "1px solid #333",
            background: `linear-gradient(90deg, #333 1px, transparent 1px),
                 linear-gradient(180deg, #333 1px, transparent 1px)`,
            backgroundSize: `${theme.typography.pxToRem(100)}
                              ${theme.typography.pxToRem(100)}`,
            backgroundPositionY: 0,
            backgroundRepeat: "round",
            order: { xs: 2, sm: 0 } // Image section comes second on mobile and first on laptop
          }}
        >
          <img
            src={endToEndShopingExp}
            style={{
              height: "auto",
              maxWidth: "120%",
              objectFit: "cover",
              zIndex: 1,
            }}
            alt="image"
          />
        </Grid>

      {/* No/Low Code Integration Text and Button Section */}
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          p: { xs: 3, sm: 6.5 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: { xs: 'center', md: 'flex-start' }, // Center on mobile, left on larger screens
          textAlign: { xs: 'center', md: 'left' }, // Center text on mobile, left on larger screens
        }}
      >
        <Typography sx={{ fontSize: { xs: theme.typography.pxToRem(30), md: theme.typography.pxToRem(50) }, ...gradientTextStyle }}>
        From Discovery ​to Delivery{" "}
        </Typography>
        <Typography sx={{ fontSize: { xs: theme.typography.pxToRem(20), md: theme.typography.pxToRem(20) }, mt: 2 ,color:"white"}}>
        ULai ensures a smooth and ​personalized shopping ​experience, guiding customers ​effortlessly from finding the ​perfect product to receiving it ​at their doorstep. Our robust ​post-purchase support handles ​all queries, ensuring customer ​satisfaction even after the sale.
        </Typography>
        <Box
          sx={{
            mt: 6,
            width: "100%",
            display: "flex",
            justifyContent: { xs: "center", md: "center" }, // Center button on mobile, left on larger screens
          }}
        >
              <Button
            variant="outlined"
            type="submit"
            color="primary"
            size="small"
            disableRipple
            sx={{
              mt: 6,
              color: "white",
              borderRadius: (theme) => theme.typography.pxToRem(28),
              padding: (theme) =>
                `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
                  20
                )}`,
              textTransform: "none",
              fontWeight: "bold",
              backgroundColor: "primary.button1",
              fontSize: {
                xs: theme.typography.pxToRem(16),
                md: theme.typography.pxToRem(20),
              },
              "&:hover": {
                backgroundColor: "primary.button1",
                borderColor: "primary.button1",
                boxShadow: (theme) =>
                  `0 0 ${theme.typography.pxToRem(6)} #fff`,
              },
            }}
            onClick={() => setCalendlyOpen(!calendlyOpen)}
          >
            Book Demo
          </Button>
        </Box>
      </Grid>
    </Grid>}
    </Container>
</Box>
</Stack>
  );
}

export default EndtoEndShopingExperience;
