import React, { useEffect } from "react";
import { Container, Typography, Box } from "@mui/material";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position to top on page load
  }, []);
  return (
    <Container style={{

    
    }}>
      <Box
        sx={{
          padding: 4,
          color: "#fff",
          borderRadius: 2,
          mt: 8,
        
      
        }}
      >
        <Typography
          sx={{ justifyContent: "center", display: "flex" }}
          variant="h2"
          gutterBottom
        >
          Privacy Policy
        </Typography>
        <Typography paragraph>
          In order to provide our services to you, we will, from time to time,
          collect, store, use, process, and transfer certain personal
          information about you. This privacy policy (Privacy Policy) governs
          the collection and use of personal information of users by Goexcelsior
          Private Limited.
        </Typography>
        <Typography paragraph>
          This Privacy Policy, read together with the terms and service of our
          mobile application (App) and the terms of use of the Website,
          constitutes a legal and binding agreement between you and Sashakti
          Ventures Private Limited and is enforceable under the laws of India.
        </Typography>
        <Typography paragraph>
          The Privacy Policy is subject to revisions at any time, as determined
          by us, without notice, and any such changes are effective immediately
          upon being posted on the Website and the App; any use of the Website
          or the App thereafter will be deemed to be an acceptance of these
          changes by you.
        </Typography>
        <Typography paragraph>
          You are strongly urged to read this Privacy Policy in its entirety and
          to periodically check this page to understand how modifications or
          revisions to this policy affects the use of our information. Ulai
          shall not be responsible for your failure to remain informed about
          such changes.
        </Typography>
        <Typography variant="h4" gutterBottom>
          Types of Information collected:
        </Typography>
        <Typography paragraph>
          We collect the following types of information from its users:
        </Typography>
        <Typography paragraph>
          - Name
          <br />
          - E-mail address
          <br />
          - Location and/or address
          <br />
          - Phone number
          <br />- Account password
        </Typography>
        <Typography paragraph>
          We do not collect any information which may contain 'sensitive
          personal data or information' as defined under the Information
          Technology (Reasonable Security Practices and Procedures and Sensitive
          Personal Data or Information) Rules, 2011 (Data Protection Rules).
        </Typography>
        <Typography paragraph>
          The information above will be collected and retained by the following
          entity:
        </Typography>
        <Typography paragraph>
          Sashakti Ventures Private Limited
          <br />
          #309, Bharat Nilaya,
          <br />
          Kundalahalli, Bangalore- 37
          <br />
          Phone: +91 9845178656 | E-mail: hello@ulai.in
        </Typography>
        <Typography variant="h4" gutterBottom>
          Purpose of collection:
        </Typography>
        <Typography paragraph>
          We use the information collected from you for the provision of
          services to you. You will, at all times, have the option to refrain
          from disclosing your personal information to us. In such cases, we
          will no longer be obliged to continue providing services to you or
          continue to provide you with access to the App.
        </Typography>
        <Typography paragraph>
          By signing up for an account with us, you consent to receiving updates
          related to your account (such as, sign up confirmation, updated terms
          of service, account expiry, etc) on WhatsApp.
        </Typography>
        <Typography variant="h4" gutterBottom>
          Disclosure:
        </Typography>
        <Typography paragraph>
          We will disclose any personal information if we are ordered to do so
          by a court of competent jurisdiction or to any government agency if
          required by law. Other than this, we will not disclose your personal
          information to any third parties.
        </Typography>
        <Typography variant="h4" gutterBottom>
          Security Practices & Procedures:
        </Typography>
        <Typography paragraph>
          We will ensure the confidentiality and security of the personal
          information of our users by implementing comprehensive information
          security practices and standards as mandated by the Data Protection
          Rules. We have adopted the best security standard to protect the
          confidentiality and security of your information.
        </Typography>
        <Typography variant="h4" gutterBottom>
          Grievance Officer:
        </Typography>
        <Typography paragraph>
          If you have any concerns or questions in relation to this Privacy
          Policy you may address them to our grievance officer whose email
          address is as follows: hello@ulai.in
        </Typography>
        <Typography paragraph>
          If you would like your account and all associated data to be deleted
          from our platform, please email your deletion request to hello@ulai.in
        </Typography>
        <Typography
          sx={{ justifyContent: "flex-end", display: "flex" }}
          variant="subtitle1"
          gutterBottom
        >
          Last Updated 01 Feb, 2024
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
