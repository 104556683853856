import * as React from "react";
import {
  Grid,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import ULAILogoFooter from "Assets/ULAILogoFooter.svg";
import { Copyright, Handshake } from "@mui/icons-material";
import { FaInstagram, FaFacebook, FaProductHunt } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaRegNewspaper } from "react-icons/fa6";
import { MdOutlineWorkOutline, MdConnectWithoutContact } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function BottomFooter({ theme, activeTab, setActiveTab ,calendlyOpen,setCalendlyOpen}) {
  const  navigate=useNavigate()
  const SocialLinks = (
    <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
      <Box
        component="span"
        onClick={() => window.open("https://www.linkedin.com/company/ulai")}
        sx={{ cursor: "pointer" }}
      >
        <IoLogoLinkedin size={24} />
      </Box>
      <Box
        component="span"
        onClick={() => window.open("https://www.facebook.com/ulaidotin/")}
        sx={{ cursor: "pointer" }}
      >
        <FaFacebook size={24} />
      </Box>
      <Box
        component="span"
        onClick={() => window.open("https://www.instagram.com/ulaidotin/")}
        sx={{ cursor: "pointer" }}
      >
        <FaInstagram size={24} />
      </Box>
      {/* <Box
        component="span"
        onClick={() => window.open("https://www.producthunt.com/products/ulai")}
        sx={{ cursor: "pointer" }}
      >
        <FaProductHunt size={24} />
      </Box> */}
    </Stack>
  );

  return (
    <footer
      style={{
        backgroundColor: "white",
        color: "black",
        padding: theme.typography.pxToRem(40),
         position:"static"
      }}
    >
      <Stack sx={{ width: "100%", alignItems: "center" ,mt:4}}>
        {/* For Larger Screens */}
        <Grid container spacing={3} sx={{ display: { xs: "none", md: "flex" }, width: '80%', mx: 'auto' }} >
          <Grid item xs={12} md={3}  >
            <img src={ULAILogoFooter} alt="ULAI Logo" style={{ width: "100px" }} />
            <Typography sx={{ fontWeight: "bold", mt: 2 }}>
              Boost support and customer satisfaction with AI-powered WhatsApp journeys. Perfect for rapidly growing e-commerce brands.
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}  >
            <Typography ml={4} sx={{ fontWeight: "bold" }}>Resource</Typography>
            <Typography ml={4}  mt={1}>Customer Stories</Typography>
            <Typography ml={4}  mt={1}>Case Studies</Typography>
            <Typography ml={4}  mt={1}>Appreciation</Typography>
            <Typography ml={4}  mt={1}>Blog</Typography>
          </Grid>
          <Grid item xs={12} md={3} >
            <Typography sx={{ fontWeight: "bold" }}>Company</Typography>
            <Typography
              onClick={() => window.open("https://www.linkedin.com/company/ulai/jobs/")}
              sx={{ display: "flex", alignItems: "center", cursor: "pointer", mt: 1 }}
            >
              <MdOutlineWorkOutline style={{ marginRight: '8px' }} /> Careers
            </Typography>
            <Typography sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <FaRegNewspaper style={{ marginRight: '8px' }} /> News
            </Typography>
            <Typography sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <Handshake style={{ marginRight: '8px' }} /> Partnerships
            </Typography>
            <Typography sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <MdConnectWithoutContact style={{ marginRight: '8px' }}  /> Contact Us
            </Typography>
          </Grid>
          <Grid item xs={12} md={3 }  >
            <Typography sx={{ fontWeight: "bold" }}>Social</Typography>
            {SocialLinks}
          </Grid>
        </Grid>

        {/* For Smaller Screens */}
        <Box sx={{ display: { xs: "block", md: "none" }, width: "90%", mx: "auto" }} >
          <Stack direction="column" spacing={2} sx={{ alignItems: "center", mb: 2 }}>
            <img src={ULAILogoFooter} alt="ULAI Logo" style={{ width: "100px" }} />
            <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
              Boost support and customer satisfaction with AI-powered WhatsApp journeys. Perfect for rapidly growing e-commerce brands.
            </Typography>
          </Stack>
          <Grid container spacing={2} sx={{ mb: 2 }} justifyContent={"space-between"} >
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: "bold" }}>Resource</Typography>
              <Typography mt={2}>Customer Stories</Typography>
              <Typography mt={2}>Case Studies</Typography>
              <Typography mt={2}>Appreciation</Typography>
              <Typography mt={2}>Blog</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography ml={4}sx={{ fontWeight: "bold" }}>Company</Typography>
              <Typography
              ml={4}
                onClick={() => window.open("https://www.linkedin.com/company/ulai/jobs/")}
                sx={{ display: "flex", alignItems: "center", cursor: "pointer", mt: 2 }}
              >
                <MdOutlineWorkOutline style={{ marginRight: '8px' }} /> Careers
              </Typography>
              <Typography ml={4} sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <FaRegNewspaper style={{ marginRight: '8px' }} /> News
              </Typography>
              <Typography ml={4} sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                <Handshake style={{ marginRight: '8px' }} /> Partnerships
              </Typography>
              <Typography ml={4} sx={{ display: "flex", alignItems: "center", mt: 2 }} >
                <MdConnectWithoutContact style={{ marginRight: '8px' }}    /> Contact Us
              </Typography>
            </Grid>
          </Grid>
          <Typography sx={{ mb: 1, textAlign: "center" }}>Get in Touch</Typography>
          <Stack direction="row" spacing={2} sx={{ mb: 2, justifyContent: "center" }}>
            {SocialLinks}
          </Stack>
          {/* <Typography sx={{ mt: 2, textAlign: "center" }}>Get in Touch</Typography> */}
        </Box>

        {/* Footer Bottom Links */}
        <hr style={{ 
          border: '1px solid black', 
          margin: `${theme.typography.pxToRem(50)} 0`,
          width: '70%',
          marginLeft: 'auto',
          marginRight: 'auto'
        }} />

        <Box sx={{ width: "70%", display: "flex", flexDirection: "column", alignItems: "center",justifyContent:"cneter" }} >
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <Typography
              sx={{ cursor: "pointer", padding: "0 3px" }}
              onClick={() =>  navigate("/data-policy")}
              textAlign={"center"}
            >
              Data Policy
            </Typography>
            <Typography>|</Typography>
            <Typography
              sx={{ cursor: "pointer", padding: "0 3px" }}
              onClick={() => navigate("/privacy-policy")}
              textAlign={"center"}
            >
              Privacy Policy
            </Typography>
            <Typography>|</Typography>
            <Typography
              sx={{ cursor: "pointer", padding: "0 3px" }}
              onClick={() => navigate("/terms-condition")}
              textAlign={"center"}
            >
              Terms & Conditions
            </Typography>
          </Box>
          <Typography sx={{ display: "flex", alignItems: "center" }}>
            <Copyright /> &nbsp;Sashakti Ventures Pvt. Ltd.
          </Typography>
        </Box>
      </Stack>
    </footer>
  );
}

export default BottomFooter;
