import * as React from "react";
import { Grid, Typography, Box, Button, Card, Stack, Container } from "@mui/material";
import comprehensiveAnalytics2 from "Assets/comprehensiveAnalytics2.svg";
import comprehensiveAnalytics3 from "Assets/comprehensiveAnalytics3.svg";
import comprehensiveAnalytics4 from "Assets/comprehensiveAnalytics4.svg";
import Slider from "react-slick";
import { gradientTextStyle } from "../style/gardientText";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TwoWayWhatsappMarketing from "./TwoWayWhatsappMarketing";

function ComprehensiveAnalytics({ refMap, theme, calendlyOpen, setCalendlyOpen }) {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600);
 const ref=React.useRef()
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let sliderRef = React.useRef(null);

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    prevArrow: <></>,
    nextArrow: <></>,
    appendDots: (dots) => (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
        <ul style={{ margin: -10, padding: 0, display: 'flex', listStyleType: 'none' }}>
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: 'white',
          margin: '0 5px', // Add space between dots
        }}
      ></div>
    ),
  };

  const chatInsights = [
    
    { img: comprehensiveAnalytics2 },
    { img: comprehensiveAnalytics3 },
    { img: comprehensiveAnalytics4 },
  ];

  return (
    <Stack sx={{ width: "100%", alignItems: "center" }} ref={refMap} >
      <Box sx={{ width: { xs: "100%", md:"80%",lg: "70%" }, mt: isMobile ? 0 : 10 }}>
        <Container>
        {isMobile ? (

<Grid container>
        {/* Text Section */}
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
               background: "linear-gradient(90deg, #000000 22.09%, #131313 91.65%)",
            p: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "flex-start" }, // Center align on mobile
            textAlign: { xs: "center", sm: "left" }, // Center text on mobile
            order: { xs: 1, sm: 1 } // Text section is first on mobile and same on laptop
          }}
        >
        <Typography
    sx={{
      fontSize: { xs: theme.typography.pxToRem(30), md: theme.typography.pxToRem(50) },
      ...gradientTextStyle,
    }}
  >
    AI-Powered Marketing Mastery
  </Typography>
  <Typography
    sx={{
      fontSize: { xs: theme.typography.pxToRem(20), md: theme.typography.pxToRem(20) },
      mt: 2,
      mb:2,
      color: "white",
    }}
  >
    Dive into the future of customer engagement with our AI-driven Marketing and support insights. Understand customer trends, campaign effectiveness, and key business metrics and connect with your audience like never before.
  </Typography>
        </Grid>

        {/* Image Section */}
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            p: {xs:2,sm:6.5},
            color: "white",
            display: "flex",
            gap: 4,
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            borderRight: { xs: "none", sm: "1px solid #333" }, // Remove right border on mobile
            borderBottom: "1px solid #333",
            background: `linear-gradient(90deg, #333 1px, transparent 1px),
                 linear-gradient(180deg, #333 1px, transparent 1px)`,
            backgroundSize: `${theme.typography.pxToRem(100)}
                              ${theme.typography.pxToRem(100)}`,
            backgroundPositionY: 0,
            backgroundRepeat: "round",
            order: { xs: 2, sm: 0 } // Image section comes second on mobile and first on laptop
          }}
        >
   <Card
  sx={{
    backgroundColor: "primary.cardBackground",
    borderRadius: theme.typography.pxToRem(45),
    p: { xs: "15px", sm: 4 },
    color: "white",
    display: "flex",
    flexDirection: "column",
    gap: { xs: 2, sm: 4 },
    background: "transparent",
    width: { xs: "100%", sm: "100%" },
    height: "100%", // Set height to full for uniform size
   
  }}
>
  <Slider ref={(slider) => (sliderRef.current = slider)} {...settings}>
    {chatInsights.map((chat, index) => (
      <Box
        key={index}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "200%",
          width: "200%",
          overflow: "hidden",
        }}
      >
        <img
          src={chat.img}
          alt={`Slide ${index + 1}`}
          style={{
            width: "100%",
            height: "auto",
            maxWidth: "100%",
            objectFit: "cover",
            zIndex: 5,
          }}
        />
      </Box>
    ))}
  </Slider>
</Card>
        </Grid>

        {/* Button Section */}
        <Grid
          item
          xs={12}
          sx={{
            display: { xs: "flex", sm: "none" }, // Display only on mobile view
            justifyContent: "center", // Center button on mobile
            order: { xs: 3, sm: 2 }, // Button section comes last on mobile
            p: 6.5,
          }}
        >
          <Box
            sx={{
              mt: 2,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              size="small"
              disableRipple
              sx={{
                color: "white",
                borderRadius: (theme) => theme.typography.pxToRem(28),
                padding: (theme) =>
                  `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
                    20
                  )}`,
                textTransform: "none",
                fontWeight: "bold",
                backgroundColor: "primary.button1",
                fontSize: {
                  xs: theme.typography.pxToRem(16),
                  md: theme.typography.pxToRem(20),
                },
                "&:hover": {
                  backgroundColor: "primary.button1",
                  borderColor: "primary.button1",
                  boxShadow: (theme) =>
                    `0 0 ${theme.typography.pxToRem(6)} #fff`,
                },
              }}
              onClick={() => setCalendlyOpen(!calendlyOpen)}
            >
              Book Demo
            </Button>
          </Box>
        </Grid>
      </Grid>) : (

          <Grid container  alignItems={"center"} justifyContent={"center"}>
          {/* Timer Icon and Text Section */}
          <Grid
              item
              xs={12}
              sm={6}
              sx={{
                p: 0,
                color: "white",
                display: "flex",
                gap: 4,
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                borderRight: { xs: "none", sm: "1px solid #333" }, // Remove right border on mobile
                borderBottom: "1px solid #333",
                background: `linear-gradient(90deg, #333 1px, transparent 1px),
                     linear-gradient(180deg, #333 1px, transparent 1px)`,
                backgroundSize: `${theme.typography.pxToRem(100)}
                                  ${theme.typography.pxToRem(100)}`,
                backgroundPositionY: 0,
                backgroundRepeat: "round",
                order: { xs: 2, sm: 0 } // Image section comes second on mobile and first on laptop
              }}
            >
      <Card
              sx={{
                 backgroundColor: "primary.cardBackground",
                 borderRadius: theme.typography.pxToRem(45),
                 p: { xs: "20px", sm: 4 },
                 color: "white",
                 display: "flex",
                 flexDirection: "column",
                 gap: { xs: 2, sm: 4 },
                 background: "transparent",
                 width: { xs: "100%", sm: "100%" },
                 height: "100%", // Set height to full for uniform size
              
               }}
           >
               <Slider ref={(slider) => (sliderRef.current = slider)} {...settings}>
               {chatInsights.map((chat, index) => (
                   <Box
                     key={index}
                     sx={{
                       display: "flex",
                       justifyContent: "center",
                       alignItems: "center",
                       height: "100%",
                       width: "100%",
                       overflow: "hidden",
                     }}
                   >
                     <img
                       src={chat.img}
                       alt={`Slide ${index + 1}`}
                    style={{
                         width: "100%",
                         height: "auto",
                         maxWidth: "100%",
                         objectFit: "cover",
                         zIndex: 5,
                     }}
                />
                   </Box>
                 ))}
              </Slider>
            </Card>
            </Grid>
    
          {/* No/Low Code Integration Text and Button Section */}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
               background: "linear-gradient(90deg, #000000 22.09%, #131313 91.65%)",
              p: { xs: 3, sm: 6.5 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'center', md: 'flex-start' }, // Center on mobile, left on larger screens
              textAlign: { xs: 'center', md: 'left' }, // Center text on mobile, left on larger screens
            }}
          >
            <Typography sx={{ fontSize: { xs: theme.typography.pxToRem(30), md: theme.typography.pxToRem(50) }, ...gradientTextStyle }}>
            AI-Powered Marketing Mastery
            </Typography>
            <Typography sx={{ fontSize: { xs: theme.typography.pxToRem(20), md: theme.typography.pxToRem(20) }, mt: 2 ,color:"white"}}>
            Dive into the future of customer engagement with our AI-driven Marketing and support insights. Understand customer trends, campaign effectiveness, and key business metrics and connect with your audience like never before.
            </Typography>
            <Box
              sx={{
                mt: 6,
                width: "100%",
                display: "flex",
                justifyContent: { xs: "center", md: "center" }, // Center button on mobile, left on larger screens
              }}
            >
                  <Button
                variant="outlined"
                type="submit"
                color="primary"
                size="small"
                disableRipple
                sx={{
                  mt: 6,
                  color: "white",
                  borderRadius: (theme) => theme.typography.pxToRem(28),
                  padding: (theme) =>
                    `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
                      20
                    )}`,
                  textTransform: "none",
                  fontWeight: "bold",
                  backgroundColor: "primary.button1",
                  fontSize: {
                    xs: theme.typography.pxToRem(16),
                    md: theme.typography.pxToRem(20),
                  },
                  "&:hover": {
                    backgroundColor: "primary.button1",
                    borderColor: "primary.button1",
                    boxShadow: (theme) =>
                      `0 0 ${theme.typography.pxToRem(6)} #fff`,
                  },
                }}
                onClick={() => setCalendlyOpen(!calendlyOpen)}
              >
                Book Demo
              </Button>
            </Box>
          </Grid>
        </Grid>
   
        )}
        </Container>
      </Box>
    </Stack>
  );
}

export default ComprehensiveAnalytics;
