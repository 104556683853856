import * as React from "react";
import openAILogo from "Assets/openAILogo.svg";
import { Button, Box, Typography, Container, Stack } from "@mui/material";
import { gradientTextStyle } from "../style/gardientText";

function TopGridInfoBanner({ theme }) {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth >= 1480);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth >= 1480);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [])
  return (
   
    <Box
      sx={{
        background: `linear-gradient(90deg, #333 1px, transparent 1px),
             linear-gradient(180deg, #333 1px, transparent 1px)`,
        backgroundSize: `${theme.typography.pxToRem(100)} ${theme.typography.pxToRem(100)}`,
        backgroundPositionY: 0,
        backgroundRepeat: "round",
        borderBottom: "1px solid #333",
      
      }}
    >
      <Stack sx={{ width: "100%", alignItems: "center" }}>
        <Box sx={{ width: { xs: "90%", md: "70%" } }}>
          <Container sx={{ padding: `${theme.typography.pxToRem(50)} 0` }}>
            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
                padding: theme.typography.pxToRem(30),
              }}
            >
              <Button
                variant="outlined"
                type="submit"
                color="primary"
                size="small"
                disableRipple
                startIcon={<img src={openAILogo} alt="OpenAI Logo" />}
                sx={{
                  my: 2,
                  color: "white",
                  border: `${theme.typography.pxToRem(1)} solid`,
                  borderColor: "primary.white",
                  borderRadius: theme.typography.pxToRem(28),
                  textTransform: "none",
                  fontWeight: "bold",
                  backgroundColor: "primary.mainLite",
                  margin: `${theme.typography.pxToRem(14)} 0 `,
                  cursor: "auto",
                  "&:hover": {
                    backgroundColor: "primary.mainLite",
                    borderColor: "primary.white",
                  },
                }}
              >
                <Typography
                  className="rainbow rainbow_text_animated"
                  sx={{ fontSize: theme.typography.pxToRem(14) }}
                >
                  Powered by GPT-4 from OpenAI
                </Typography>
              </Button>
            </Box>

            <Typography
              sx={{
                fontSize: {
                  xs: theme.typography.pxToRem(35),
                  md: "62px"
                },
                fontWeight: "700",
                ...gradientTextStyle,
                lineHeight: {
                  xs: theme.typography.pxToRem(45),
                  md: theme.typography.pxToRem(90),
                
                },
                textAlign: "center",
                padding:isMobile?10:0,
                paddingTop:0,
                paddingBottom:0,
                paddingTop: theme.typography.pxToRem(45),
              }}
            >
              Delivering Personalized Attention ​To Every Customer On Your Site
            </Typography>


            <Typography
              sx={{
                fontSize: {
                  xs: theme.typography.pxToRem(20),
                  md: theme.typography.pxToRem(30),
                },
                fontWeight: "700",
                lineHeight: {
                  xs: theme.typography.pxToRem(45),
                  md: theme.typography.pxToRem(70),
                
                },
                textAlign: "center",
                paddingTop: theme.typography.pxToRem(45),
                color:"white"
              }}
            >
             Tireless, 24/7 AI-Powered Digital Salesman
            </Typography>
         

            <Box sx={{ justifyContent: "center", display: "flex" }}>
              <Button
                variant="outlined"
                type="submit"
                color="primary"
                size="small"
                disableRipple
                sx={{
                  mt: 6,
                  color: "white",
                  borderRadius: theme.typography.pxToRem(50),
                  padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(20)}`,
                  textTransform: "none",
                  fontSize: {
                    xs: theme.typography.pxToRem(20),
                    md: theme.typography.pxToRem(30),
                  },
                  backgroundColor: "primary.button2",
                  "&:hover": {
                    backgroundColor: "primary.button2",
                    borderColor: "primary.button2",
                    boxShadow: `0 0 ${theme.typography.pxToRem(6)} #fff`,
                  },
                }}
                onClick={() => window.open("https://app.ulai.in/user/login")}
              >
                Start 14 days free trial
              </Button>
            </Box>

            <Typography
              sx={{
                textAlign: "center",
                fontSize: theme.typography.pxToRem(20),
                color: theme.palette.primary.grey,
                mt: 2,
              }}
            >
              No credit cards. 30-day money back guarantee, No strings attached
            </Typography>
          </Container>
        </Box>
      </Stack>
    </Box>

  );
}

export default TopGridInfoBanner;
