import * as React from "react";
import { Grid, Typography, Box, Button, Stack } from "@mui/material";
import UlaiConnections from "Assets/UlaiConnections.png";
import { gradientTextStyle } from "../style/gardientText";

function SeamlessIntegration({ refMap, theme, calendlyOpen, setCalendlyOpen }) {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [])
  return (
    <Stack sx={{ width: "100%", alignItems: "center",mt: isMobile?0:10 }}>
      <Box
        sx={{
          width: { xs: "100%", md: "80%",lg:"70%" }, // 100% width on mobile, 70% on laptop and above
        }}
      >
        <Grid
          ref={refMap}
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "center",
          }}
          container
          spacing={2}
        >
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize:
                  window.innerWidth > 750
                    ? theme.typography.pxToRem(50)
                    : theme.typography.pxToRem(30),
                ...gradientTextStyle,
                textAlign: "center",
                width: "70%",
              }}
            >
              Seamless Integration, Everywhere:  ​We've got you covered across all ​platforms
            </Typography>
            <Typography
              sx={{
                fontSize:
                  window.innerWidth > 750
                    ? theme.typography.pxToRem(30)
                    : theme.typography.pxToRem(20),
                    color:"white"
              }}
            >
              Everywhere we've got you covered.
            </Typography>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: { sm: "100%", md: "55%" },
                // background: "radial-gradient(circle closest-side, rgba(112, 25, 255, 0.9) 0%, rgba(112, 25, 255, 0) 100%)",
                // backgroundSize: "80% 80%", // Make the gradient smaller
                // backgroundPosition: "center", // Center the gradient
                // backgroundRepeat: "no-repeat", // Ensure the gradient does not repeat
                borderRadius: theme => theme.typography.pxToRem(16),
                padding: theme => theme.typography.pxToRem(2),
                boxSizing: "border-box",
                position: "relative", // Ensure that the gradient is contained
                overflow: "hidden", // Hide any overflow to maintain cleaner edges
              }}
            >
              <img
                src={UlaiConnections}
                style={{
                  width: "80%",
                  height: "auto",
                  maxWidth: "80%",
                  objectFit: "contain",
                }}
                loading="lazy"
                alt="Seamless Integration"
              />
            </Box>
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              size="small"
              disableRipple
              sx={{
                mt: 6,
                color: "white",
                borderRadius: (theme) => theme.typography.pxToRem(28),
                padding: (theme) =>
                  `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(
                    20
                  )}`,
                textTransform: "none",
                fontWeight: "bold",
                backgroundColor: "primary.button1",
                fontSize: {
                  xs: theme.typography.pxToRem(16),
                  md: theme.typography.pxToRem(20),
                },
                "&:hover": {
                  backgroundColor: "primary.button1",
                  borderColor: "primary.button1",
                  boxShadow: (theme) =>
                    `0 0 ${theme.typography.pxToRem(6)} #fff`,
                },
              }}
              onClick={() => setCalendlyOpen(!calendlyOpen)}
            >
              Book Demo
            </Button>
          </Box>
        </Grid>
      </Box>
    </Stack>
  );
}

export default SeamlessIntegration;
