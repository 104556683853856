// SkeletonLoader.js
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoader = () => (
  <div style={{ width: '100%', height: '100%',color:"rgba(85, 8, 210, 1)" }}>
    <Skeleton height="100%" />
  </div>
);

export default SkeletonLoader;
