import React from "react";
import Slider from "react-slick";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
  Stack
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image4 from "Assets/Partners/image4.svg";
import customerStories1 from "Assets/customerStories1.png";
import customerStories2 from "Assets/customerStories2.png";
import customerStories3 from "Assets/customerStories3.png";
import {
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import { gradientTextStyle } from "../style/gardientText";

const CustomerStories = ({ setActiveTab, refMap, theme }) => {
  let sliderRef = React.useRef(null);

  const slideLeft = () => {
    sliderRef.slickPrev();
  };
  const slideRight = () => {
    sliderRef.slickNext();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <></>,
    nextArrow: <></>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cards = [
    {
      customerStoriesImg: customerStories1,
      content:
        "Contrary to popular belief, Lorem Ipsum is simply random text. It has roots in a piece of classical Latin literature from ",
      companyImage: image4,
    },
    {
      customerStoriesImg: customerStories2,
      content:
        "Contrary to popular belief, Lorem Ipsum is simply random text. It has roots in a piece of classical Latin literature from ",
      companyImage: image4,
    },
    {
      customerStoriesImg: customerStories3,
      content:
        "Contrary to popular belief, Lorem Ipsum is simply random text. It has roots in a piece of classical Latin literature from ",
      companyImage: image4,
    },
  ];

  return (
    <Box
      ref={refMap}
      sx={{
        mt: 5,
        padding: `${theme.typography.pxToRem(80)} 0`,
        width: "100%",
      }}
    >
      <Stack sx={{ width: "100%", alignItems: "center" }}>
        <Stack sx={{ width: { xs: "90%", sm: "85%", md: "70%" } }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: `${theme.typography.pxToRem(40)} 0`,
            }}
          >
            <Typography
              sx={{
                fontSize:
                  window.innerWidth > 750
                    ? theme.typography.pxToRem(30)
                    : theme.typography.pxToRem(20),
                    ...gradientTextStyle
              }}
            >
              Customer stories to Inspire You
            </Typography>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: theme.typography.pxToRem(10),
              }}
            >
              <IconButton
                onClick={slideLeft}
                sx={{
                  color: "white",
                  borderRadius: "30px",
                  border: "1px solid #333333",
                }}
              >
                <ArrowBack />
              </IconButton>
              <IconButton
                onClick={slideRight}
                sx={{
                  color: "white",
                  borderRadius: "30px",
                  border: "1px solid #333333",
                  ...gradientTextStyle
                }}
              >
                <ArrowForward />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ padding: `0 ${theme.typography.pxToRem(24)}` }}>
            <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
              {cards.map((card, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Card
                    sx={{
                      width: "95%",
                      borderRadius: theme.typography.pxToRem(35),
                      backgroundColor: "#0D0D0D",
                      color: "white",
                    }}
                  >
                    <CardContent sx={{ padding: "0px" }}>
                      <img
                        src={card.customerStoriesImg}
                        alt="a"
                        style={{ width: "100%" }}
                      />
                      <Box sx={{ padding: "0 16px" }}>
                        <Typography
                          variant="body2"
                          sx={{
                            padding: `${theme.typography.pxToRem(15)} 0
                              ${theme.typography.pxToRem(40)} 0`,
                            fontSize:
                              window.innerWidth > 750
                                ? theme.typography.pxToRem(20)
                                : theme.typography.pxToRem(16),
                          }}
                        >
                          {card.content}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            variant="outlined"
                            disableRipple
                            sx={{
                              borderRadius: "30px",
                              border: "1px solid #333333",
                              color: "white",
                              gap: "20px",
                              ...gradientTextStyle,
                              height: "50px",
                              "&:hover": {
                                border: "1px solid white",
                              },
                            }}
                            onClick={() => setActiveTab("caseStudies")}

                          >
                            Read More
                            <ArrowForward />
                          </Button>
                          <img
                            src={card.companyImage}
                            alt={""}
                            height={50}
                            width={150}
                          />
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              ))}
            </Slider>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CustomerStories;
