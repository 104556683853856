import React from 'react';
import { Grid, Typography, Box, Stack } from '@mui/material';
import { gradientTextStyle } from '../style/gardientText';
import ab from "../Assets/ab.svg";
import ab1 from "../Assets/ab1.svg";
import absmall from "../Assets/absmall.svg";

const ResponsiveTable = ({  theme ,refMap}) => {
  const data = [
    {
      keyapi: "Customer ​interaction",
      traditional1: "Predefined ​responses",
      traditional2: "Static menus",
      ulai1: "Natural ​conversations",
      ulai2: "Tailored to ​individual needs"
    },
    {
      keyapi: "Sales ​impact",
      traditional1: "Minimal sales ​influence",
      traditional2: "Basic interaction",
      ulai1: "Proactive ​recommendations",
      ulai2: "Boosts ​upselling/cross-​selling"
    },
    {
      keyapi: "Operational ​Efficiency",
      traditional1: "Requires manual ​updates",
      traditional2: "Needs human ​intervention",
      ulai1: "Automates ​responses",
      ulai2: "Reduces labor costs"
    },
    {
      keyapi: "Conversion ​rates",
      traditional1: "Passive response",
      traditional2: "Reacts to direct ​queries",
      ulai1: "Engages actively",
      ulai2: "Enhances ​conversion through ​personalization"
    },
    {
      keyapi: "Customer ​insights",
      traditional1: "Limited data ​collection",
      traditional2: "Mostly ​transactional",
      ulai1: "Deep insights",
      ulai2: "Informs marketing ​strategies"
    },
    {
      keyapi: "Scalability",
      traditional1: "Struggles with ​high volumes",
      traditional2: "Limited ​complexity",
      ulai1: "Scales easily",
      ulai2: "Handles more ​customers without ​extra costs"
    },
    {
      keyapi: "Market ​reach",
      traditional1: "Language ​limitations",
      traditional2: "Regional ​constraints",
      ulai1: "Multilingual support",
      ulai2: "Expands global ​reach"
    },
    {
      keyapi: "Analytics ​reporting",
      traditional1: "Basic analytics",
      traditional2: "Often manual ​compilation",
      ulai1: "Real-time analytics",
      ulai2: "Dynamic strategy ​adjustments"
    }
  ];

  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 700);
  const [isMedium, setIsMedium] = React.useState(window.innerWidth >= 700 && window.innerWidth < 1000);

  React.useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 700);
      setIsMedium(width >= 700 && width < 1000);
    };

    window.addEventListener("resize", handleResize);
    // Initial check
    handleResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Stack sx={{ width: '100%', alignItems: 'center' }} ref={refMap}>
      <Box sx={{ width: { xs: '90%', md: '90%' }, mt: 5, backgroundColor: 'black', color: 'white', borderRadius: '8px', padding: 2 }}>
        <Grid
        
          sx={{
            mt: 0,
            display: "flex",
            justifyContent: "center",
          }}
          container
          spacing={2}
        >
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: window.innerWidth > 750 ? theme.typography.pxToRem(50) : theme.typography.pxToRem(30),
                ...gradientTextStyle,
                textAlign: "center",
                width: "70%",
              }}
            >
              What Changes With ULai
            </Typography>
            <Typography
              sx={{
                fontSize: window.innerWidth > 750 ? theme.typography.pxToRem(50) : theme.typography.pxToRem(30),
                ...gradientTextStyle,
                textAlign:"center"
              }}
            >
              Thats Keeps Every Customer Happy 
            </Typography>
            {/* medium screen */}
            {isMedium?<Grid sx={{gridTemplateColumns:"repeat(2,1fr)",display:"grid",gap:2}}>
             
            {data.map((ele,i)=><Stack sx={{ flexDirection: "column", gap: 2, mt: 2, width: "100%", justifyContent: 'center', alignItems: "center" }}>
                  {/* Consistent Box Size */}
                  <Box sx={{ width: "350px", height: "135px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "30px 30px 0px 0px", background: "#454862" }}>
                    <Stack justifyContent={"center"} alignItems={"center"}>
                      <Typography sx={{
                        background: "#353854", borderRadius: "28px", fontSize:
                          window.innerWidth > 750
                            ? theme.typography.pxToRem(20)
                            : theme.typography.pxToRem(16),
                        p: "4px",
                        pl: "6px", pr: "8px", mb: 2
                      }}>
                        Traditional Approach
                      </Typography>

                      <Typography sx={{
                        fontSize:
                          window.innerWidth > 750
                            ? theme.typography.pxToRem(20)
                            : theme.typography.pxToRem(20),
                      }}>{ele.traditional1}</Typography>
                      <Typography sx={{
                        fontSize:
                          window.innerWidth > 750
                            ? theme.typography.pxToRem(20)
                            : theme.typography.pxToRem(20),
                      }}>{ele.traditional2}</Typography>
                    </Stack>
                  </Box>
                  <Box sx={{ width: "220px", height: "150px", position: 'relative', border: "2px solid #996DE0", borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "none" }}>
                    <img src={ab1} style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover"
                    }} />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        textAlign: 'center',
                        color: 'white',
                      }}
                    >
                      <Typography sx={{

                        fontSize:
                          window.innerWidth > 750
                            ? theme.typography.pxToRem(20)
                            : theme.typography.pxToRem(20),
                      }}>{ele.keyapi}</Typography>
                      {/* <Typography sx={{

                        fontSize:
                          window.innerWidth > 750
                            ? theme.typography.pxToRem(30)
                            : theme.typography.pxToRem(20),
                      }}>Interaction</Typography> */}
                    </Box>
                  </Box>
                  <Box sx={{ width: "350px", height: "135px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "0px 0px 30px 30px", background: "#5508D2" }}>
                    <Stack justifyContent={"center"} alignItems={"center"}>
                      <Typography sx={{
                        fontSize:
                          window.innerWidth > 750
                            ? theme.typography.pxToRem(20)
                            : theme.typography.pxToRem(20),
                      }}>{ele.ulai1}</Typography>
                      <Typography sx={{
                        fontSize:
                          window.innerWidth > 750
                            ? theme.typography.pxToRem(20)
                            : theme.typography.pxToRem(20),
                            textAlign:'center'
                      }}>{ele.ulai2}</Typography>

                      <Typography sx={{
                        background: "#353854", borderRadius: "28px", fontSize:
                          window.innerWidth > 750
                            ? theme.typography.pxToRem(20)
                            : theme.typography.pxToRem(16),
                        p: "4px",
                        pl: "6px", pr: "8px", mt: 2
                      }}>
                        ULai benefits
                      </Typography>
                    </Stack>
                  </Box>
                </Stack>)}
                

            </Grid>
            :isMobile ? (
              <Stack sx={{ pl: 2 }}> 
              {/* ----------------------------one---------------------  */}
             {data.map((ele,i)=>(
        <Stack
        sx={{
          flexDirection: "column",
          gap: 0,
          mt: 2,
          width: "100%",
          justifyContent: 'center',
          alignItems: "center",
        }}
      >
        {/* Top Box */}
        <Box
          sx={{
            width: "300px",
            height: "70px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "30px 30px 0px 0px",
            background: "#454862",
            p: 1,
          }}
        >
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ textAlign: "center" }}
          >
            <Typography
              sx={{
                background: "#353854",
                borderRadius: "28px",
                fontSize: window.innerWidth > 750
                  ? theme.typography.pxToRem(12)
                  : theme.typography.pxToRem(12),
                p: "2px",
                pl: "6px",
                pr: "8px",
                mt:1,
                mb:0.5,
                whiteSpace: "nowrap", // Prevents text from wrapping to a new line
                overflow: "hidden",   // Ensures that overflow text does not display
                textOverflow: "ellipsis" // Adds ellipsis if text overflows
              }}
            >
              Traditional Approach
            </Typography>
      
            <Typography
              sx={{
                fontSize: window.innerWidth > 750
                  ? theme.typography.pxToRem(16)
                  : theme.typography.pxToRem(16),
                whiteSpace: "nowrap", // Prevents text from wrapping to a new line
                overflow: "hidden",   // Ensures that overflow text does not display
                textOverflow: "ellipsis" // Adds ellipsis if text overflows
              }}
            >
              {data[i].traditional1}
            </Typography>
            <Typography
              sx={{
                fontSize: window.innerWidth > 750
                  ? theme.typography.pxToRem(16)
                  : theme.typography.pxToRem(16),
                whiteSpace: "nowrap", // Prevents text from wrapping to a new line
                overflow: "hidden",   // Ensures that overflow text does not display
                textOverflow: "ellipsis",
                mb:1, // Adds ellipsis if text overflows
              }}
            >
              {data[i].traditional2}
            </Typography>
          </Stack>
        </Box>
      
        {/* Key API Box with Image */}
        <Box
          sx={{
            width: "200px",
            height: "30px",
            position: "relative",
            textAlign: "center",
            mb: 2, // Margin bottom to create space between this and the next Box
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src="https://ulai.in/images/down-arrow.png"
            alt="Arrow"
            style={{
              width: "100%",
              height: "50%",
              top:25,  // Adjusted height to maintain aspect ratio
              objectFit: "inherit",
              position: 'absolute', // Position the image absolutely within the Box
              zIndex: 1,  // Ensure the image is above the text
            }}
          />
          <Box
            sx={{
              position: "relative", // Make this box relative to contain the text
              zIndex: 0,  // Ensure the text is behind the image
              textAlign: "center",
              color: "white",
            }}
          >
            <Typography
              sx={{
                fontSize: window.innerWidth > 750
                  ? theme.typography.pxToRem(16)
                  : theme.typography.pxToRem(16),
                whiteSpace: "nowrap", // Prevents text from wrapping to a new line
                overflow: "hidden",   // Ensures that overflow text does not display
                textOverflow: "ellipsis", // Adds ellipsis if text overflows
              }}
            >
              {data[i].keyapi}
            </Typography>
          </Box>
        </Box>
      
        {/* Bottom Box */}
        <Box
          sx={{
            width: "300px",
            height: "70px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "0px 0px 30px 30px",
            background: "#5508D2",
            p: 1,
          }}
        >
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ textAlign: "center" }}
          >
            <Typography
              sx={{
                mt:1,
                fontSize: window.innerWidth > 750
                  ? theme.typography.pxToRem(16)
                  : theme.typography.pxToRem(16),
                whiteSpace: "nowrap", // Prevents text from wrapping to a new line
                overflow: "hidden",   // Ensures that overflow text does not display
                textOverflow: "ellipsis" // Adds ellipsis if text overflows
              }}
            >
              {data[i].ulai1}
            </Typography>
            <Typography
              sx={{
                fontSize: window.innerWidth > 750
                  ? theme.typography.pxToRem(16)
                  : theme.typography.pxToRem(16),
                whiteSpace: "nowrap", // Prevents text from wrapping to a new line
                overflow: "hidden",   // Ensures that overflow text does not display
                textOverflow: "ellipsis" // Adds ellipsis if text overflows
              }}
            >
              {data[i].ulai2}
            </Typography>
      
            <Typography
              sx={{
                background: "#353854",
                borderRadius: "28px",
                fontSize: window.innerWidth > 750
                  ? theme.typography.pxToRem(14)
                  : theme.typography.pxToRem(12),
                p: "2px",
                pl: "6px",
                pr: "8px",
                mb:1,
                mt:0.5,
                whiteSpace: "nowrap", // Prevents text from wrapping to a new line
                overflow: "hidden",   // Ensures that overflow text does not display
                textOverflow: "ellipsis" // Adds ellipsis if text overflows
              }}
            >
              Ulai benefits
            </Typography>
          </Stack>
        </Box>
      </Stack>
           ))}

              
               {/* --------------------------------two-------------------------- */}

              </Stack>
            ) : 
              <>
                <Stack sx={{ width: "100%", flexDirection: "row", gap: 0 }}>
                  <Stack sx={{ width: "350px", height: "150px", justifyContent: "center", alignItems: 'center' }}>
                    <Typography sx={{
                      fontSize: window.innerWidth > 750 ? theme.typography.pxToRem(30) : theme.typography.pxToRem(20),
                    }}>
                      Traditional Approach
                    </Typography>
                  </Stack>
                  <Stack sx={{ width: "200px", height: "150px", justifyContent: "center", alignItems: 'center' }}>
                    <Typography sx={{
                      fontSize: window.innerWidth > 750 ? theme.typography.pxToRem(30) : theme.typography.pxToRem(20),
                    }}>
                      Key KPIs
                    </Typography>
                  </Stack>
                  <Stack sx={{ width: "350px", height: "150px", justifyContent: "center", alignItems: 'center' }}>
                    <Typography sx={{
                      fontSize: window.innerWidth > 750 ? theme.typography.pxToRem(30) : theme.typography.pxToRem(20),
                    }}>
                      ULai benefits
                    </Typography>
                  </Stack>
                </Stack>
                {data.map((ele, i) => (
           <Stack key={i} sx={{ flexDirection: "row", gap: 0, mt: 2, width: "100%" }}>
           {/* First Box */}
           <Box
             sx={{
               width: "350px",
               height: "100px",
               display: "flex",
               flexDirection: "column",
               justifyContent: "center",
               alignItems: "flex-start",
               borderRadius: "30px 0 0 30px",
               background: "#454862",
               padding: 2,
               overflow: "auto",
             }}
           >
             <Typography
               sx={{
                 textAlign: "left",
                 fontSize: window.innerWidth > 750 ? theme.typography.pxToRem(20) : theme.typography.pxToRem(16),
                 lineHeight: 1.2,
                 whiteSpace: "normal",
                 mb: 1,
               }}
             >
               • {ele.traditional1}
             </Typography>
             <Typography
               sx={{
                 textAlign: "left",
                 fontSize: window.innerWidth > 750 ? theme.typography.pxToRem(20) : theme.typography.pxToRem(16),
                 lineHeight: 1.2,
                 whiteSpace: "normal",
               }}
             >
               • {ele.traditional2}
             </Typography>
           </Box>
         
           {/* SVG Box */}
           <Box
             sx={{
               width: "200px",
               height: "100px",
               position: "relative",
               overflow: "hidden",
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
             }}
           >
             <svg
               width="100%"
               height="100%"
               viewBox="0 0 252 155"  // Adjusted viewBox to fit the SVG correctly
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
               style={{ objectFit: "contain" }}
             >
               <path d="M1 154V1H186.844L252 80.2818L186.844 154H1Z" stroke="#996DE0" strokeWidth="2"/>
             </svg>
             <Box
               sx={{
                 position: "absolute",
                 top: "50%",
                 left: "50%",
                 transform: "translate(-50%, -50%)",  // Centered properly
                 textAlign: "center",
                 color: "white",
                 px: 1,
                 overflow: "auto",
               }}
             >
               <Typography
                 sx={{
                   fontSize: window.innerWidth > 750 ? theme.typography.pxToRem(20) : theme.typography.pxToRem(16),
                   lineHeight: 1.2,
                   whiteSpace: "normal",
                 }}
               >
                 {ele.keyapi}
               </Typography>
             </Box>
           </Box>
         
           {/* Third Box */}
           <Box
             sx={{
               width: "350px",
               height: "100px",
               display: "flex",
               flexDirection: "column",
               justifyContent: "center",
               alignItems: "flex-start",
               borderRadius: "0 30px 30px 0",
               background: "#5508D2",
               padding: 1,
               overflow: "auto",  // Changed from hidden to auto
             }}
           >
            <ul style={{
        
 listStyleType:"disc",
  paddingLeft: 20,
  margin: 0,
  width: '100%',
  listStylePosition: 'outside', // Ensure the bullets are positioned outside the content
}}>
  <li style={{
    textAlign: "left",
    fontSize: window.innerWidth > 750 ? theme.typography.pxToRem(20) : theme.typography.pxToRem(16),
    lineHeight: 1.2,
    whiteSpace: "normal",
    marginBottom: 8, // Adjusted spacing
  }}>
     • {ele.ulai1}
  </li>
  <li style={{
    textAlign: "left",
    fontSize: window.innerWidth > 750 ? theme.typography.pxToRem(20) : theme.typography.pxToRem(16),
    lineHeight: 1.2,
    whiteSpace: "normal",
    marginBottom: 8, // Adjusted spacing
  }}>
   • {ele.ulai2}
  </li>
</ul>

           </Box>
         </Stack>
                  
                ))}
              </>}
          </Box>
        </Grid>
      </Box>
    </Stack>)
}

export default ResponsiveTable;

