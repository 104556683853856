
import { Stack, Typography } from "@mui/material";
import CustomerStories from "Component/CustomerStories";
import leaf from "Assets/leaf.png"
import * as React from "react";
const typographyStyle = {

  color: "black",
  fontSize: "45px",
  fontWeight: 600,
  lineHeight: "56.7px",
};
function CaseStudies({ refMap, theme, setActiveTab }) {
  return (
    <div style={{ marginTop: "150px" ,background:"white"}}>
      <Stack sx={{width:"100%",alignItems:"center"}}>
      <div style={{width:"73%"}}>
      <Typography style={{...typographyStyle,  maxWidth: "1200px"}}>Evlogia turns to ULai to drive commerce and automate support</Typography>
        {/* <CustomerStories
        setActiveTab={setActiveTab}
        theme={theme}
      /> */}
      <img src={leaf} alt="leaf"  style={{ width: "100%" ,marginTop:"60px"}} />
          <CustomerStories
        setActiveTab={setActiveTab}
        theme={theme}
      />  

      
      </div>
      {/* <Typography>Evlogia turns to ULai to drive commerce and automate support</Typography>
      <CustomerStories
        setActiveTab={setActiveTab}
        theme={theme}
      /> */}
      </Stack>
    </div>
  );
}
export default CaseStudies;
