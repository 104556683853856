import React, { useEffect } from "react";
import { Container, Typography, Box } from "@mui/material";

const FairUsagePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position to top on page load
  }, []);
  return (
    <Container>
      <Box
        sx={{
          padding: 4,
          color: "#fff",
          borderRadius: 2,
          mt: 8,
        }}
      >
        <Typography
          sx={{ justifyContent: "center", display: "flex" }}
          variant="h2"
          gutterBottom
        >
          Fair Usage Policy
        </Typography>
        <Typography variant="h4" gutterBottom>
          Monthly Active Contacts (MAC)
        </Typography>
        <Typography paragraph>
          MAC is Monthly Active Contact. An active contact is an end user that
          has exchanged at least one message with your business over WhatsApp in
          a given month.
        </Typography>
        <Typography variant="h4" gutterBottom>
          Fair Use Policy:
        </Typography>
        <Typography paragraph>
          The Monthly Active Contact fee per month is subject to a fair use
          policy of maximum 50 messages (of any type) per customer per month.
        </Typography>
        <Typography paragraph>
          You will be charged one additional Monthly Active User fee for every
          50 messages exchanged with one user. For example, if you exchange say,
          101 messages with a single user then you will be charged for three
          users.
        </Typography>
        <Typography
          sx={{ justifyContent: "flex-end", display: "flex" }}
          variant="subtitle1"
          gutterBottom
        >
          Last Updated 01 Feb, 2024
        </Typography>
      </Box>
    </Container>
  );
};

export default FairUsagePolicy;
