
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
export default function AnimatedSection({ children, animationVariant, delay = 0 }) {
    const controls = useAnimation();
    const [ref, inView] = useInView({ threshold: 0.2 });
  
    useEffect(() => {
      if (inView) {
        controls.start("visible");
      } else {
        controls.start("hidden");
      }
    }, [controls, inView]);
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={animationVariant}
        transition={{ duration: 0.2, delay }}
        className="snap-section"
      >
        {children}
      </motion.div>
    );
  }