import React, { useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Homepage from "./Homepage";
import './App.css';
import Router from "./Routes/Router";
import { useLocation } from "react-router-dom";
import FixedHeader from "./Component/FixedHeader";
import BottomFooter from "./Component/BottomFooter";

import { PopupModal } from "react-calendly";
function App() {
  const {pathname} = useLocation();
  const theme = createTheme({
    mode: "dark",
    typography: {
      fontFamily: `'Outfit', sans-serif`,
      pxToRem: size => `${size / 18.018}rem`,
      pxTovh: size => `${(size*100)/940}rem`,
      pxTovw: size => `${(size*100)/1920}rem`,
    },
    palette: {
      primary: {
        main: "#000",
        mainLite: "rgba(27, 27, 27)",
        white: "#fff",
        grey: "#b1b1b1",
        button1: "#00BD52",
        button2: "#7019FF",
        cardBackground: "#0D0D0D",
      },
    },
  });
  const [activeTab, setActiveTab] = React.useState("Home");
  const [calendlyOpen, setCalendlyOpen] = React.useState(false);

  const engagementRef = React.useRef(null);
  const ecomJourneyRef = React.useRef(null);
  const whatsappChannelRef = React.useRef(null);
  const comprehensiveAnalyticsRef = React.useRef(null);
  const integrationsRef = React.useRef(null);
  const customerStoriesRef = React.useRef(null);
  const appreciationRef = React.useRef(null);
const  codeIntegrationRef=React.useRef(null);
const ulaiBenefitsRef = React.useRef(null);
const trustedBrandsRef = React.useRef(null);
const supportedByRef = React.useRef(null);
const scheduleDemoRef = React.useRef(null);

  const handleRef = (id) => {
    const offset = 80; // Adjust this value according to the height of your fixed header
    let targetRef;
  
    switch (id) {
      case "#engagement":
        targetRef = engagementRef;
        break;
      case "#ecom-journey":
        targetRef = ecomJourneyRef;
        break;
      case "#whatsapp-channel":
        targetRef = whatsappChannelRef;
        break;
      case "#comprehensive-analytics":
        targetRef = comprehensiveAnalyticsRef;
        break;
      case "#code-integration":
        targetRef = codeIntegrationRef; // Make sure you have this ref defined
        break;
      case "#integrations":
        targetRef = integrationsRef;
        break;
      case "#ULai-benefits":
        targetRef = ulaiBenefitsRef; // Make sure you have this ref defined
        break;
      case "#trusted-brands":
        targetRef = trustedBrandsRef; // Make sure you have this ref defined
        break;
      case "#supported-by":
        targetRef = supportedByRef; // Make sure you have this ref defined
        break;
      case "#schedule-demo":
        targetRef = scheduleDemoRef; // Make sure you have this ref defined
        break;
      default:
        return;
    }
  
    const element = targetRef.current;
  
    if (element) {
      const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: elementTop - offset,
        behavior: "smooth"
      });
    }
  };
  
  useEffect(() => {
    const handleScroll = () => {
    //  console.log(window.scrollY,"===========>soclroee");
      // You can also perform other actions based on scroll position here
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <ThemeProvider theme={theme}>

     <FixedHeader
        theme={theme}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        calendlyOpen={calendlyOpen}
        setCalendlyOpen={setCalendlyOpen}
        handleRef={handleRef}
      />
 <Router
  calendlyOpen={calendlyOpen}
  setCalendlyOpen={setCalendlyOpen}
  activeTab={activeTab}
  setActiveTab={setActiveTab}
  appreciationRef={appreciationRef}
  customerStoriesRef={customerStoriesRef}
  engagementRef={engagementRef}
  ecomJourneyRef={ecomJourneyRef}
  whatsappChannelRef={whatsappChannelRef}
  comprehensiveAnalyticsRef={comprehensiveAnalyticsRef}
  integrationsRef={integrationsRef}
  codeIntegrationRef={codeIntegrationRef}
  ulaiBenefitsRef={ulaiBenefitsRef}
  trustedBrandsRef={trustedBrandsRef}
  supportedByRef={supportedByRef}
  scheduleDemoRef={scheduleDemoRef}
/>
<div id="bookAdemoButton"></div>
        <PopupModal
          url="https://calendly.com/ulai/30min?hide_landing_page_details=1&hide_gdpr_banner=1'"
          onModalClose={() => setCalendlyOpen(!calendlyOpen)}
          open={calendlyOpen}
          rootElement={document.getElementById("bookAdemoButton")}
          text="Book Demo"
          textColor="#ffffff"
        />
 {pathname!="/"?<BottomFooter
            theme={theme}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />:null}
          
    </ThemeProvider>
   
  );
}

export default App;

