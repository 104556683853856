import * as React from "react";
import { Grid, Typography, Box } from "@mui/material";
import image1 from "Assets/Partners/image1.svg";
import image2 from "Assets/Partners/image2.svg";
import image3 from "Assets/Partners/image3.svg";
import image4 from "Assets/Partners/image4.svg";
import { gradientTextStyle } from "../style/gardientText";

const imgObjArray = [
  {
    imgUrl: "https://ulai.in/images/customer-aabo.png",
    title: "Text 1",
  },
  {
    imgUrl: "https://ulai.in/images/customer-hd.png",
    title: "Text 2",
  },
  {
    imgUrl:"https://ulai.in/images/customer-evlogia.png",
    title: "Text 3",
  },
  {
    imgUrl: "https://ulai.in/images/customer-chirp.png",
    title: "Text 4",
  },
];

function InfiniteCrousel({ theme ,refMap}) {
  return (
    <Grid
      sx={{
        mt: 5,
        display: "flex",
        justifyContent: "center",
      }}
      container
      spacing={2}
      ref={refMap}
    >
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            paddingBottom: theme.typography.pxToRem(50),
            pl:2,
            fontSize:
              window.innerWidth > 750
                ? theme.typography.pxToRem(50)
                : theme.typography.pxToRem(30),
               ...gradientTextStyle,
               textAlign:"center"
          }}
        >
          Trusted by awesome consumer brands
        </Typography>
        <div className="carousel-container">
          <div className="carousel-track">
            {imgObjArray?.map((imgObj) => (
              <div className="carousel-card" key={imgObj.title}>
                <img src={imgObj?.imgUrl} alt={imgObj?.title ?? ""} />
              </div>
            ))}
            {imgObjArray.map((imgObj) => (
              <div className="carousel-card" key={imgObj.title}>
                <img src={imgObj?.imgUrl} alt={imgObj?.title ?? ""} />
              </div>
            ))}
          </div>
        </div>
        <div className="stacked-images-container">
          {imgObjArray.map((imgObj) => (
            <div className="stacked-image" key={imgObj.title}>
              <img src={imgObj?.imgUrl} alt={imgObj?.title ?? ""} />
            </div>
          ))}
        </div>
      </Box>
    </Grid>
  );
}

export default InfiniteCrousel;
