import * as React from "react";
import { Box, Stack } from "@mui/material";
import TopBannerImg from "Assets/TopBannerImg.png";
import landing from "../Assets/landing.png";
import landing2 from "../Assets/landing2.png";
import landing3 from "../Assets/landing3.png";
import Slider from "react-slick";

const ResponsiveBanner = ({ theme , setImage1Show ,image1Show, setShowLanding3,showLanding3, handleTouch}) => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600);
  const sliderRef = React.useRef(null);
  // const [image1Show, setImage1Show] = React.useState(false);
  const [inView, setInView] = React.useState(false);
  // const [showLanding3, setShowLanding3] = React.useState(false);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <></>,
    nextArrow: <></>,
    appendDots: (dots) => (
      <Box
        sx={{
          position: "relative",
          bottom: isMobile?0: -10,
          left: "50%",
          zIndex:1,
          transform: "translateX(-50%)",
          "& ul": {
            margin: 0,
            padding: 0,
            display: "flex",
            listStyle: "none",
            justifyContent: "center",
          },
          "& li": {
            margin: "0 5px",
          },
        }}
      >
        <ul>{dots}</ul>
      </Box>
    ),
    customPaging: () => (
      <Box
        sx={{
          width: "9px",
          height: "9px",
          borderRadius: "50%",
          background: "white",
        }}
      ></Box>
    ),
  };


const url=["https://ulai.in/images/mobile-hero-banner-01.png","https://ulai.in/images/mobile-hero-banner-02.png"]
  const ref = React.useRef(null);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
        } else {
          setInView(false);
          resetStates(); // Reset states when not in view
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  React.useEffect(() => {
    if (inView) {
      const timer = setTimeout(() => {
        setImage1Show(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [inView]);

  const resetStates = () => {
    setImage1Show(false);
    setShowLanding3(false);
  };

  // const handleTouch = () => {
  //   if (image1Show) {
  //     setShowLanding3(!showLanding3); // Toggle between landing2 and landing3
  //   } else {
  //     setImage1Show(true);
  //   }
  // };

  return (
    <Box
      ref={ref}
      sx={{
        mt: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: { xs: "auto", md: "50%" },
        width: "100%",
        overflow: "hidden",
        position: "relative",
       
      }}
      onTouchStart={handleTouch}
    >
      <Stack
        sx={{
          width: "100%",
          alignItems: "center",
          px: { xs: 2, sm: 4, md: 0 },
        }}
      >
        <Box
          sx={{
            width: {
              xs: "90%",
       
              md:"80%",
              lg: "70%",
            },
            maxWidth: "1200px",
            position: "relative",
          }}
        >
         {isMobile ?  <Slider ref={sliderRef} {...settings}>
              {url.map((chat, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height:"auto",
                    width: "110%",
                    width: { sm: "100%", md: "70%" },
                    background: "radial-gradient(circle closest-side, rgba(112, 25, 255, 0.9) 0%, rgba(112, 25, 255, 0) 100%)",
                    backgroundSize: "80% 80%", // Make the gradient smaller
                    backgroundPosition: "center", // Center the gradient
                    backgroundRepeat: "no-repeat", // Ensure the gradient does not repeat
                    borderRadius: theme => theme.typography.pxToRem(16),
                    padding: theme => theme.typography.pxToRem(2),
                    boxSizing: "border-box",
                    position: "relative", // Ensure that the gradient is contained
                    overflow: "hidden", 
                    
                  }}
                >
    
                  <img
                    src={chat}
                    style={{
                      width: "110%",
                      height: "95%",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                    loading="lazy"
                    alt={`Chat Insight ${index + 1}`}
                  />
                 
                </Box>
              ))}
            </Slider>:<img
            src={isMobile ? landing : "https://ulai.in/images/hero-banner-01.jpeg"}
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
              filter: isMobile && image1Show ? "blur(1px)" : "none",
              transition: "filter 0.3s ease-in-out",
            }}
            loading="egar"
            alt="Main Banner"
          />}
     
        </Box>
      </Stack>
    </Box>
  );
};

export default ResponsiveBanner;
