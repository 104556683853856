import * as React from "react";
import {
  Box,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
  CircularProgress,
  Skeleton
} from "@mui/material";
import check from "../Assets/check.png";  // Ensure the correct path
import cancel from "../Assets/cancel.png";  // Ensure the correct path
import { Navigate } from "react-router-dom";
import SkeletonLoader from "./SkeletonLoader";
import { PopupModal } from "react-calendly";

function PricingPage({ theme, plans, planFeaturesList, calendlyOpen, setCalendlyOpen }) {

  React.useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position to top on page load
  }, []);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 600);
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [])
  // Define your plan headers here
  const [headers, setHeaders] = React.useState([
    "Plan",
  ]);

  // Define feature rows here
  const [featureRows, setFeatures] = React.useState([



    {
      feature: (<Box>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>AI Features</Typography>
      </Box>),
      data: [

      ]
    },
    {
      feature: (
        <Box>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Whatsapp</Typography>

        </Box>
      ),
      data: [

      ]
    },
    {
      feature: (
        <Box>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Channels</Typography>

        </Box>
      ),
      data: [

      ]
    },
    {
      feature: "Integration", data: [

      ]
    },
    {
      feature: "Dashboard", data: [

      ]
    },
    {
      feature: "Agent Support", data: [

      ]
    },
    {
      feature: "Support", data: [

      ]
    },
    {
      feature: "Price", data: [

      ]
    },
    {
      feature: <Box>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>Connect Us</Typography>
      </Box>,
      data: [

      ]
    },
  ])

  React.useEffect(() => {
    const apiCall = async () => {
      setLoading(true)
      try {
        const res = await fetch("https://app.ulai.in/api/admin/get_plans")
        const data = await res.json()

        if (data.success === true) {
          const {
            agentFeatures,
            aiFeatures,
            channelsFeatures,
            dashboardFeatures,
            integrationFeatures,
            planFeatures,
            supportFeatures,
            whatsappFeatures,

          } = data.data[0]
          setHeaders(["Plan", ...data.data.map((ele, i) => ele.title)])
          setFeatures([
            {
              feature: (
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) } }}>AI Features</Typography>
                </Box>
              ),
              data: data.data.map((ele, i) =>
                ele.aiFeatures.map((feature, idx) => (
                  <Typography
                    key={idx}
                    variant="body2"
                    sx={{
                      color: ele.title == "Scale" ? "white" : "text.secondary", // Color based on i
                      textAlign: 'center',
                      fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) },
                    }}>
                    {feature.name}{idx != (ele.aiFeatures.length) - 1 ? "," : null}
                  </Typography>
                ))
              )
            },
            {
              feature: (
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) } }}>Whatsapp</Typography>
                </Box>
              ),
              data: data.data.map((ele, i) =>
                ele.whatsappFeatures.map((feature, idx) => (
                  <Typography
                    key={idx}
                    variant="body2"
                    sx={{

                      color: ele.title == "Scale" ? "white" : "text.secondary", // Color based on i
                      textAlign: 'center',
                      fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) },
                    }}>
                    {feature.name}{idx != (ele.whatsappFeatures.length) - 1 ? "," : null}
                  </Typography>
                ))
              )
            },
            {
              feature: (
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) } }}>Channels</Typography>
                </Box>
              ),
              data: data.data.map((ele, i) =>
                ele.channelsFeatures.map((feature, idx) => (
                  <Typography
                    key={idx}
                    variant="body2"
                    sx={{
                      color: ele.title == "Scale" ? "white" : "text.secondary", // Color based on i
                      textAlign: 'center',
                      fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) },
                    }}>
                    {feature.name}{idx != (ele.channelsFeatures.length) - 1 ? "," : null}
                  </Typography>
                ))
              )
            },
            {
              feature: (
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) }, }}>Integration</Typography>
                </Box>
              ),
              data: data.data.map((ele, i) =>
                ele.integrationFeatures.map((feature, idx) => (
                  <Typography
                    key={idx}
                    variant="body2"
                    sx={{
                      color: ele.title == "Scale" ? "white" : "text.secondary", // Color based on i
                      textAlign: 'center',
                      fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) },
                    }}>
                    {feature.name}{idx != (ele.integrationFeatures.length) - 1 ? "," : null}
                  </Typography>
                ))
              )
            },
            {
              feature: (
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) }, }}>User Service Features</Typography>
                </Box>
              ),
              data: data.data.map((ele, i) =>
                ele.dashboardFeatures.map((feature, idx) => (
                  <Typography
                    key={idx}
                    variant="body2"
                    sx={{
                      color: ele.title == "Scale" ? "white" : "text.secondary", // Color based on i
                      textAlign: 'center',
                      fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) },
                    }}>
                    {feature.name}{idx != (ele.dashboardFeatures.length) - 1 ? "," : null}
                  </Typography>
                ))
              )
            },
            {
              feature: (
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) }, }}>Agent Support</Typography>
                </Box>
              ),
              data: data.data.map((ele, i) =>
                ele.agentFeatures.map((feature, idx) => (
                  <Typography
                    key={idx}
                    variant="body2"
                    sx={{
                      color: i % 2 != 0 ? "white" : "text.secondary", // Color based on i
                      textAlign: 'center',
                      fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) },
                    }}>
                    {feature.name}{idx != (ele.agentFeatures.length) - 1 ? "," : null}
                  </Typography>
                ))
              )
            },
            {
              feature: (
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) }, }}>Support</Typography>
                </Box>
              ),
              data: data.data.map((ele, i) =>
                ele.supportFeatures.map((feature, idx) => (
                  <Typography
                    key={idx}
                    variant="body2"
                    sx={{
                      color: ele.title == "Scale" ? "white" : "text.secondary", // Color based on i
                      textAlign: 'center',
                      fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) },
                    }}>
                    {feature.name}{idx != (ele.supportFeatures.length) - 1 ? "," : null}
                  </Typography>
                ))
              )
            },
            {
              feature: (
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) }, }}>Price</Typography>
                </Box>
              ),
              data: data.data.map((ele, i) =>

                <Typography
                  key={ele.title}
                  variant="body2"
                  sx={{
                    color: ele.title == "Scale" ? "white" : "text.secondary", // Color based on i
                    textAlign: 'center',
                    fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(20), md: theme.typography.pxToRem(20) },
                    fontWeight: "bold"
                  }}>
                  ${ele.price}
                </Typography>
              )
            },
            {
              feature: (
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: { xs: theme.typography.pxToRem(16), sm: theme.typography.pxToRem(16), md: theme.typography.pxToRem(16) }, }}></Typography>
                </Box>
              ),
              data: data.data.map((ele, i) => <Button onClick={() => {
                window.open(`https://app.ulai.in/user/checkout?product=${ele._id}`)
              }} key={`${ele.title}1`} variant="contained" sx={{ fontWeight: "bold", backgroundColor: "primary.button2", "&:hover": { backgroundColor: "primary.button2" }, borderRadius: "80px", width: "139px", height: "45px" }}>Start Now</Button>)
            },
          ]);
          setLoading(false)
        }
      } catch (err) {
        setLoading(false)
      }
    }
    apiCall()
  }, [])

  return (
    <Container sx={{ mb: { xs: "30px", md: "50px" }, px: { xs: 2, sm: 4, md: 8 } }}>

      <Box>
        <Typography
          variant="h2"
          sx={{
            padding: `${theme.typography.pxToRem(30)} 0`,
            fontSize: { xs: theme.typography.pxToRem(24), sm: theme.typography.pxToRem(32), md: theme.typography.pxToRem(36) },
            textAlign: { xs: "center", md: "left" },
            color:"white"
          }}
        >
          Our Flexible Pricing Options
        </Typography>
      </Box>

      <Stack
        flexDirection={{ xs: "row", md: "row" }}
        width={"100%"}
        justifyContent={"space-between"}


      >
        <Stack
          direction={"row"}
          spacing={2}
          sx={{
            width: '100%',

            // Added flexWrap for mobile
          }}
        >
          <Button sx={{ width: { xs: "140px", sm: "180px", md: "200px" }, height: "50px", background: "white", borderRadius: 90, fontWeight: 600, "&:hover": { background: "white" } }}>Billed Monthly</Button>

        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{

            mt: 0, // Removed margin-top to keep buttons in the same line
          }}
        >
          <Button disabled={true} sx={{ width: { xs: "100px", sm: "100px", md: "100px" }, height: "50px", background: "black", borderRadius: 90, fontWeight: 600 }}>USD</Button>
        </Stack>
      </Stack>
      <TableContainer component={Paper} sx={{
        marginTop: "30px",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        maxWidth: "200%", // Ensure the table does not overflow
      }} aria-label="pricing table">
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell key={index} sx={{
                  width: header === "Plan" ? isMobile ? "120px" : "150px" : "250px", // Prevent from expanding beyond this width

                  backgroundColor: header === "Scale" ? 'black' : 'white',
                  fontWeight: "bold",
                  position: index === 0 ? "sticky" : "static",
                  left: index === 0 ? 0 : "auto",
                  zIndex: index === 0 ? 1 : "auto",
                  border: header == "Scale" ? "2px solid rgba(85, 8, 210, 1)" : header == "Plan" ? "1px solid rgba(85, 8, 210, 1)" : "none",
                  borderBottom: "none",
                  borderLeft: header == "Scale" ? "2px solid rgba(85, 8, 210, 1)" : "none",
                  borderTop: "none" // Ensure no border is visible
                }}>
                 <Typography sx={{
                    fontSize: {
                      xs: theme.typography.pxToRem(16),
                      sm: theme.typography.pxToRem(20),
                      md: theme.typography.pxToRem(20),
                    },
                    textAlign: index === 0 ? "left" : "center",
                    color: header === "Scale" ? 'white' : 'black',
                    fontWeight: 700
                  }}>
                     {loading?<SkeletonLoader/>: header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {featureRows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell sx={{
                  border: "none",
                  width: "auto",
                  borderBottom: 'none',
                  background: "white",
                  fontWeight: "bold",
                  position: "sticky",
                  left: 0,
                  zIndex: 1,
                  border: "1px solid rgba(85, 8, 210, 1)",
                  borderLeft: "none",
                  borderBottom: "none",
                  borderTop: "none"// Ensure no border color is applied
                }}>
                  {loading?<SkeletonLoader/>: row.feature}
                </TableCell>
                {row.data.map((data, colIndex) => {
                  let color = ""
                  let priceColor = ""
                  // console.log(data, "=======+>data")
                  for (var i = 0; i < data.length; i++) {
                    color = (data[i].props.sx.color)

                  }

                  return <TableCell
                    key={colIndex}
                    sx={{
                      position: "relative",
                      width: "250px",
                      border: color == "white" || data.key == "Scale" || data.key == "Scale1" ? "2px solid rgba(85, 8, 210, 1)" : "none",
                      borderBottom: "none",
                      borderTop: "none",
                      // Remove bottom border for data cells
                      backgroundColor: colIndex === 1 ? 'black' : 'inherit', // Set background color for "Premium" column
                      color: colIndex === 1 ? 'white' : 'inherit', // Set text color for "Premium" column
                      // Ensure no border color is applied
                    }}
                  >
                    <Typography sx={{ textAlign: "center", color: colIndex === 1 ? 'white' : 'inherit', border: "none" }}>

                      {data}
                    </Typography>
                  </TableCell>
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  

    </Container>
  );
}

export default PricingPage;
