import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { gradientTextStyle } from "../style/gardientText";
import WadhwaniFoundationLogo from "../Assets/WadhwaniFoundationLogo.png";
import Saas_Orbit_Logo from "../Assets/SaaS_Orbit_Logo.png";
import KSUM from "../Assets/KSUM.jpg";
import IVenture from "../Assets/IVenture.png";
import IIT_Mandi from "../Assets/IIT_Mandi.png";
import II_K from "../Assets/IIT_K.jpg";
import aabo from "../Assets/aabo.png"

const logos = [
  "https://ulai.in/images/incubator-wadhwani.png",
  "https://ulai.in/images/incubator-saasorbit.png",
  "https://ulai.in/images/incubator-iitmandi.png",
 "https://ulai.in/images/incubator-iimk.png",
  "https://ulai.in/images/incubator-iventure.png",
  "https://ulai.in/images/incubator-kerala.png"
];

const Support = ({ refMap, theme }) => {
  return (
    <Stack sx={{ width: "100%", alignItems: "center", mt: 5 }} ref={refMap}>
      <Box sx={{ width: { xs: "100%", md: "80%" } }}>
        <Stack sx={{ alignItems: "center", justifyContent: "center" }}>
          <Typography
            sx={{
              fontSize: {
                xs: theme.typography.pxToRem(30),
                sm: theme.typography.pxToRem(30),
                md: theme.typography.pxToRem(50),
              },
              ...gradientTextStyle,
              textAlign: "center",
              width: "70%",
              color: "white",
            }}
          >
            Supported By
          </Typography>
        </Stack>
        <Grid
  ref={refMap}
  container
  rowSpacing={2} // Increased row gap
  columnSpacing={0} // Keep column gap the same as before
  sx={{ mt: 4, overflowX: 'hidden', justifyContent: 'center' }}
>
  {logos.map((logo, index) => (
    <Grid
      item
      key={index}
      xs={12} // Full width on mobile
      sm={6}  // Half width on small screens
      md={4}  // One third width on medium and larger screens
      sx={{ display: 'flex', justifyContent: 'center', padding: 1 }} 
  
    >
      <img
        src={logo}
        alt={`Logo ${index}`}
        style={{
          height: 'auto', // Allow height to adjust based on width
          width: '60%', // Full width of the container
           // Limit the maximum width of the logo
          objectFit: 'contain', // Ensure the image fits within its container
          display: 'block', // Remove extra space below images
        }}
        loading='lazy'
      />
    </Grid>
  ))}
</Grid>

      </Box>
    </Stack>
  );
}

export default Support;
